<div class="box-landing" [ngClass]="{'blurred': isBlured, 'conversation-started': conversationStarted}" [dir]="direction">
    <div class="gradient-landing"></div>
    <div class="inline-landing">
        <!--<app-header></app-header>-->
        <app-navbar></app-navbar>
        <div class="inline-content">
            <div class="scroll-container" #scrollContainer>
                <div class="conversation-container">
                    <app-introduction></app-introduction>
                    <app-conversation></app-conversation>
                </div>
            </div>
            <div class="related-container">
                <app-related-questions></app-related-questions>
            </div>
            <div class="controls-container">
                <app-conversation-controls></app-conversation-controls>
            </div>
        </div>
    </div>
</div>
<app-lost-network></app-lost-network>
<app-feedback idModal="feedbackModal" labelModal="feedbacklabelModal" [dir]="direction"></app-feedback>
<app-content-modal idModal="idModal" labelModal="labelModal" [dir]="direction"></app-content-modal>
