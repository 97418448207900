<div *ngIf="isRelatedQuestion && !isLoading" class="box-related-questions" appFadeInOut>
    <div class="related-questions-container-wrapper">
        <p class="related-question-title">{{ relatedQuestionsTitle }}</p>
        <ul class="related-questions-container-list">
            <button *ngFor="let question of relatedQuestionsList; let last = last" id="id-related-question"
                    class="related-question-container-item"
                    [disabled]="!isInteractionAllowed"
                    (click)="onRelatedQuestionSubmit(question.question)"
            >
                <p class="question-name">{{ question.question }}</p>
                <hr *ngIf="!last">
            </button>
        </ul>
    </div>
</div>
