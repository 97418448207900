import { EventEmitter, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { LANG_CONFIG } from "../../app.config";
import { HttpService } from "./http.service";
import {AgentModel, Project} from "../models/project";
import { Language } from "../models/language";


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config!: AgentModel
    agentId: string | null = null;
    languages: Language[] = LANG_CONFIG

    constructor(private http: HttpService) {}

    //obrisi
    /*getDirectionFromLanguage(locale: string) {
        const language = this.getLanguage(locale)
        return language ? language.direction : 'ltr'
    }

    getLanguage(locale: string) {
        return this.getLanguages().find(l => l.locale === locale)
    }*/


    // getters environment
    getHost () {
        return environment.apiUrl;
    }

    getOrchestrationHost () {
        return environment.orchestratorUrl;
    }

    getDesignType() {
      return environment.design;
    }

    getWssScheme () {
        return environment.wssScheme;
    }

    getHttpScheme () {
        return environment.httpScheme;
    }

    // getters config
    getLanguages() {
        return this.config.languages;
    }

    getShortcuts(language: string) {
        return this.config.shortcuts
            .filter(s => s.language.locale === language).map(shortcut => ({ text: shortcut.name })) ?? [];
    }

    getProjectName(): string {
        return this.config.name;
    }

    getAgentName(): string {
        return this.config.name;
    }

    //getters
    getAgentId(): string | null {
        return this.agentId;
    }

    getAnonymousId(): string {
        const declare = (key: string): string => {
            let id = localStorage.getItem(key) ?? uuidv4();
            localStorage.setItem(key, id);
            return id;
        }

        return declare("anonymousId");
    }

    getDesignId(): string {
        return '3d41f6bc-ad1e-47fe-822d-c0653c22a3f8';
    }

    //setters
    setAgentId(id: string | null) {
        this.agentId = id;
    }

    setAgentConfig (config: any) {
        this.config = config;
        this.setSelectedLanguage('en-US');
    }

    setSelectedLanguage (locale: string) {
        this.config.languages = this.config.languages.map(language => {
            return {
                id: language.id,
                name: language.name,
                shortName: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.shortName ?? ""; })(),
                icon: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.icon ?? ""; })(),
                locale: language.locale,
                direction: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.direction ?? "ltr"; })(),
                selected: language.locale === locale,
                presenter_url: '', //language.presenter_url,
                order: language.order,
                voices: this.config.voices.map(voice => voice.languageLocale === language.locale ? voice : null)
            }
        })
    }

    isMobileDevice(): boolean {
        // @ts-ignore
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

        // Check if the User-Agent matches common mobile device keywords
        const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        // Check if the screen size is typical of mobile devices
        const isSmallScreen = window.innerWidth <= 768;

        // Check if the device supports touch events
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        return isMobileUserAgent || isSmallScreen || isTouchDevice;
    }

    // methods
    async fetchConfiguration () {
        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.getHttpScheme()}://${this.getHost()}/api/agent/${this.getAgentId()}/get`)
                .setMethod("GET")
                .setHeaders({
                    "content-type": "application/json"
                })
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not get required configuration: ${e.toString()}`);
        }
    }
}
